<template>
  <div>
    <SimpleHeader :name="title" noback />
    <div class="container home" :style="fixHeight">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="user-box" @click="toPage('/info')">
            <van-image class="user-avater" :src="info.avatar_url||$config.defaultLogo"/>
            <div class="user-info">
              <div class="user-name">{{info.nickname}} <span class="user-level" v-if="info.level_name">{{info.level_name}}</span></div>
              <div class="user-num">个人ID：{{info.user_id}}</div>
            </div>
            <img class="linecontright-icon iconRight" :src="$config.arrow" alt="" />
          </div>
          <div class="money-box" >
            <div class="money-it " v-for="(item,index) in wallet" :key="index" @click="toPage(item.http_url)">
              <div class="money-it-num">{{balanceInfo[item.key]||'***'}}</div>
              <div class="money-it-name">{{item.name}}</div>
            </div>
          </div>
          <van-grid :column-num="3" class="service-box" v-if="service.length">
            <van-grid-item :icon="item.uri_icon" :text="item.name" v-for="(item,index) in service" :key="index" @click="toPage(item.http_url)"/>
          </van-grid>
      </van-pull-refresh>
    </div>

  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import { reactive, onMounted, toRefs,onActivated, inject } from 'vue'
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import usePage from '@/hooks/usePage'
import heightStyle from '@/utils/height'
import { getMineInfo} from '@/api/user'
import { getWallet} from '@/api/wallet'
import to from 'await-to-js';
import { useStore } from 'vuex'
export default {
  name: 'home',
  components: {
    // [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { fixHeight } = heightStyle()
    const { navigateTo } = usePage()
    const store = useStore()
    const state = reactive({
      info: {},
      wallet: [],
      service: [],
      loading: false,
      refreshing:false,
      balanceInfo: {},
      title:''
    })

    const getAmount = async ()=>{
      let [error,result] = await to(getWallet())
      if(error) return;
      let {code, res} = result;
      if (code == 0) {
        state.balanceInfo = res
      }
    }

    //刷新页面
    const onRefresh = () => {
      state.loading = true
      getData()
    }

    const toPage = url => {
      if(!url){
        tips();
        return
      }
      navigateTo(url)
    }

    const tips = () => {
      Toast('敬请期待')
    }

    const initData = ()=> {
      state.info = {}
      state.service = []
      state.wallet = []
    }

    async function getData() {
      if (state.refreshing) {
        initData()
        state.refreshing = false
      }
      let [error,result] = await to(getMineInfo())
      state.loading = false
      _appLoadFanish()
      if(error) return;
      let {code, res} = result;
      if (code === 0) {
        state.info = res.info
        state.service = res.service
        state.wallet = res.wallet
        // state.title = res.
      }
    }

    onMounted(async () => {
      state.title = store.getters.projectInfo?.project_name
      getAmount()
      getData()
    })
    onActivated(async () => {
      if (!state.service.length || !state.wallet.length) {
        getAmount()
        getData()
      } else {
         _appLoadFanish()
      }
    })
    onBeforeRouteUpdate((to, from) => {
      //仅当 id 更改时才获取用户，例如仅 query 或 hash 值已更改
     console.log(to, from)
    })

    return {
      ...toRefs(state),
      toPage,
      onRefresh,
      fixHeight
    }
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/base/mixin';
.container {
  overflow-y: auto;
  min-height: 100%;
  background: #fff;
  padding: 0 16px;
  .boxSizing();
  .user-box{
    /* margin-top: 32px; */
    display:flex;
    margin: 32px 0 16px;
    align-items: center;
    .user-avater{
      .wh(60px, 60px);
      .borderRadius(60px);
      overflow: hidden;
      margin-right: 12px;
    }
    .user-info{
      flex: 1;
      .user-name{
        .sc(16px,#2F3032,500);
        line-height: 22px;
        margin-bottom: 4px;
      }
      .user-num{
        line-height: 20px;
        .sc(14px,#7E838B,400);
      }
      .user-level{
        margin-left: 10px;
        display: inline-block;
        padding: 0 6px;
        line-height: 18px;
        .sc(11px,#FFEFBC,400);
        border-radius: 2px;
        height: 18px;
        line-height: 18px;
        background: #000;
      }
    }
  }
  .money-box{
    display: flex;
    align-items: center;
    height: 80px;
    background-image: linear-gradient(270deg, #D5BE8C 0%, #B08F5D 100%);
    .borderRadius(8px);
    .money-it{
      flex: 1;
      text-align: center;
      .money-it-num{
        .sc(16px,#fff,500)
      }
      .money-it-name{
        margin-top: 2px;
        .sc(13px,#fff,400)
      }
    }
  }
  .service-box{
    display: grid;
    grid-template-columns: repeat(auto-fill, 33.33%);
    grid-template-rows: repeat(auto, 97px);
    border: 0.5px solid #E3E3E5;
    // border-bottom: none;
    .boxSizing();
    .borderRadius(8px);
    overflow: hidden;
    margin-top: 16px;
    &:deep(.van-grid-item__content::after) {
      z-index: 1;
      border: 0 solid #E3E3E5;
      border-width: 0 1px 1px 0;
      transform: scale(.5);
    }
    &:deep(.van-grid-item__text){
      font-size: 12px;
      margin-top: 0;
    }
     &:deep(.van-icon__image){
      width: 28px;
      height: 28px;
    }
    .service-it{
      // margin: 1px;
      width: 100%;
      height:97px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background: #fff;
      border-right: 1px solid #E3E3E5;
      border-bottom: 1px solid #E3E3E5;
      // border-left: 1px solid #E3E3E5;
      .boxSizing();
      .service-icon{
        .wh(40px,40px);
      }
      .service-name{
        .sc(12px,#686C74)
      }
      &+.service-it{
        border-left: none;
      }
      &:nth-of-type(3n){
        border-right: none;
      }
      &-more{

      }
    }
  }
}
</style>
