import usePage from '@/hooks/usePage'
import { ref, onMounted } from 'vue'

export default () => {
  const fixHeight = ref(0)
  const { getFixHeight } = usePage()
  onMounted(async () => {
    fixHeight.value = await getFixHeight()
  })

  return { fixHeight }
}
